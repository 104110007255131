<template>
  <div class="page_404" @click="gotoHome">
    <img :src="src" alt="404" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: require("@/assets/images/404.jpg"),
    };
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
img, .page_404 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
  cursor: pointer;
}
</style>>